import React from "react"
import Accordion from "react-bootstrap/Accordion"
import moment from "moment"

function CalendarEvent({ start, end, title, details, location, ...props }) {
  details = details.replace('<html-blob>', '').replace('</html-blob>', '')
  let venue = ''
  if(location !== null) {
    venue = location.split(',')[0]
  }
  return (
    <Accordion.Item className="mt-0 mb-2" {...props}>
        <Accordion.Header className="mb-0">
          <strong className="text-nowrap">
            <time datetime={moment(start).format('YYYY-MM-DD')}>{moment(start).format("DD MMM")}</time> 
          </strong>
          <span class="text-truncate ms-2">
            {title}
            <span className="text-muted ms-2 small">{venue}</span>
          </span>
        </Accordion.Header>
        <Accordion.Body className="text-primary pt-0">
          <div dangerouslySetInnerHTML={{ __html: details}} className="text-book" />
          <p className="text-muted small mt-2">
            <span>
              <time datetime={moment(start).format('YYYY-MM-DD HH:mm')}>
                {moment(start).format('DD MMMM YYYY, HH:mm')}
              </time>–
              <time datetime={moment(end).format('YYYY-MM-DD HH:mm')}>
                {moment(end).format('HH:mm')}
              </time>
            </span>
            {' • '}
            {location}
          </p>
        </Accordion.Body>
    </Accordion.Item>
  )
}



function Calendar({ events }) {
  return (
    <Accordion defaultActiveKey={['0']} className="mt-3">
      {events.map((event, index) => 
        <CalendarEvent 
          start={event.start.dateTime} 
          end={event.end.dateTime}
          title={event.title} 
          location={event.location}
          details={event.details}
          eventKey={index}
          />
      )}
    </Accordion>
  )
}

export default Calendar