import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import "../fragments"
import Features from "../components/features/features"
import News from "../components/news"
import Calendar from "../components/calendar"

const intro = `
  Although I started singing as a child, I was well in my twenties when I rediscovered
  singing in choirs. A few years later I started studying classical 
  voice with Karin van der Poel in Utrecht. Besides solo singing, choral 
  repertoire remains close to my heart. I participated in Meesters&Gezellen 
  multiple times, and currently sing in Consensus Vocalis and the newly founded 
  ensemble Fonos.
`


export default function MusicPage({ data }) {
  const today = new Date();
  const concerts = data.concerts.nodes.filter(event =>
    new Date(event.start.dateTime) > today);

  return (
    <Layout className="page-music">
      <Container>
        <Row className="mb-5">
          <Col md={8} style={{maxWidth: 1200}}>
            <ReactMarkdown children={intro} className="lead text-primary mb-5" />
          </Col>
        </Row>
        
        <Row xs="1" lg="2">
          <Col lg="8" className="mb-5 pe-5">
            <h2 className="striped-header mb-4"><span>Updates</span></h2>
            <News items={data.latestNews.nodes} />
          </Col>
          <Col lg="4" className="mb-4">
            <h2 className="striped-header mb-5"><span>Concerts</span></h2>
            <Calendar events={concerts} />
          </Col>
        </Row>
      
        <Row>
          <Col>
            <h2 className="striped-header mb-5"><span>Recent projects</span></h2>
            <Features projects={data.projects.nodes} />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query MusicQuery {
    concerts: allCalendarEvent(
      sort: {fields: start___dateTime, order: ASC}, 
      filter: {start: {dateTime: {glob: "2022*"}}}
    ) {
      nodes {
        id
        start {
          dateTime
        }
        end {
          dateTime
        }
        title: summary
        details: description
        location
      }
    }

    latestNews: allNews(
      sort: {fields: date, order: DESC}, 
      limit: 6
      filter: {tags: {in: ["music"]}}
    ) {
      nodes {
        date
        message
        tags
      }
    }

    projects: allMusic(
      filter: {
        status: {eq: published}
      },
      sort: {fields: date, order: DESC},
      limit: 6
    ) {
      nodes {
        ...musicProperties
      }
    }
  }
`

